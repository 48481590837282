import { useEffect, useRef, useState } from 'react';

import Image from 'next/image';
import { Swiper } from 'swiper';
import {
  Navigation as SwiperNavigation,
  Pagination as SwiperPagination,
} from 'swiper/modules';

import { breakpoints } from '@hultafors/shared/constants';
import { ParttrapImage } from '@hultafors/shared/types';

import { colors, createProductImageSrc } from '@hultafors/wibe/helpers';
import { useGlobal } from '@hultafors/wibe/hooks';

import ChevronDown from '../../svg/chevron-down.svg';
import { Icon } from '../icon/icon';
import { Modal } from '../modal/modal';
import { ModalBottomSlider } from '../modal-bottom-slider/modal-bottom-slider';
import { CarbonLabel } from '../product-features/product-features.styled';
import { TextButton } from '../text-button/text-button';

import {
  AluminiumLabel,
  Container,
  FeatureListItem,
  ModalContent,
  ModalImageWrapper,
  NavigationButton,
  Pagination,
  ProductFeaturesList,
  SwiperContainer,
  SwiperSlide,
  SwiperWrapper,
  Thumbnail,
  ThumbNailWrapper,
  ZoomContainer,
} from './product-slider.styled';

interface ProductSliderProps {
  images: ParttrapImage[];
  productId?: string;
  co2Label?: string;
  showCo2Label?: boolean;
  aluminiumLabel?: string;
  showAluminiumLabel?: boolean;
}

export const ProductSlider: React.FC<ProductSliderProps> = ({
  images,
  productId,
  co2Label,
  showCo2Label,
  aluminiumLabel,
  showAluminiumLabel,
}) => {
  const { global } = useGlobal();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  // const [modalVisible, setModalVisible] = useState(false);
  const swiper = useRef<Swiper | null>(null);
  const swiperElement = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [imageIndex, setImageIndex] = useState<number>(0);

  useEffect(() => {
    if (swiperElement.current && !swiper.current) {
      swiper.current = new Swiper(swiperElement.current, {
        direction: 'horizontal',
        loop: true,
        modules: [SwiperNavigation, SwiperPagination],
        on: {
          realIndexChange: (swiper) => {
            setCurrentIndex(swiper.realIndex);
          },
        },
        pagination: {
          el: paginationRef.current,
          type: 'fraction',
        },
      });
    }
  }, []);

  const showDialog = () => {
    document.body.style.overflowY = 'hidden';
    setOpenDialog(true);
  };

  const closeDialog = () => {
    document.body.style.overflowY = 'initial';
    setOpenDialog(false);
  };

  const handleOpenModal = (index: number) => {
    setImageIndex(index);
    setOpenDialog(true);
  };

  const handleModalImageSlide = (direction: string) => {
    if (!swiper.current) {
      return;
    }
    let index;
    if (direction === 'prev') {
      index = imageIndex !== 0 ? imageIndex - 1 : images.length - 1;
      setImageIndex(index);
    }
    if (direction === 'next') {
      index = imageIndex !== images.length - 1 ? imageIndex + 1 : 0;
      setImageIndex(index);
    }
  };

  const sizes = [
    `(min-width: ${breakpoints.desktopLarge}) 474px`,
    `(min-width: ${breakpoints.desktop}) calc(50vw - 246px)`,
    `(min-width: ${breakpoints.desktopSmall}) calc(100vw - 270px)`,
    '100vw',
  ].join(', ');

  function slideMapper(image: ParttrapImage, index: number) {
    if (!image.url) {
      return null;
    }
    return (
      <SwiperSlide
        key={`ProductSlider-image-${index}`}
        className="swiper-slide"
        onClick={() => handleOpenModal(index)}
      >
        <Image
          src={`${
            image.url
          }?w=2880&h=2880&format=jpg&bgcolor=${colors.white.substring(1)}`}
          fill
          alt={
            image.description || productId ? `${productId} - ${index + 1}` : ''
          }
          sizes={sizes}
          priority={index === 0}
          loading={index === 0 ? 'eager' : 'lazy'}
        />
      </SwiperSlide>
    );
  }

  function thumbnailMapper(image: ParttrapImage, index: number) {
    if (index > 4) {
      return null;
    }
    function onClick() {
      if (!swiper.current) {
        return;
      }
      if (
        index === swiper.current.realIndex + 1 ||
        (index === 0 && swiper.current.realIndex === images.length - 1)
      ) {
        swiper.current.slideNext();
      } else if (
        index === swiper.current?.realIndex - 1 ||
        (index === images.length - 1 && swiper.current.realIndex === 0)
      ) {
        swiper.current.slidePrev();
      } else if (swiper.current.realIndex !== swiper.current.activeIndex) {
        swiper.current.slideToLoop(index);
      } else {
        swiper.current.slideTo(index);
      }
    }
    return (
      <Thumbnail
        key={`productItem${index}`}
        $active={currentIndex === index}
        onClick={onClick}
      >
        <Image
          src={`${
            image.url
          }?w=2880&h=2880&format=jpg&bgcolor=${colors.white.substring(1)}`}
          alt={
            image.description || productId ? `${productId} - ${index + 1}` : ''
          }
          width={82}
          height={82}
        />
      </Thumbnail>
    );
  }

  function slidePrev() {
    swiper.current?.slidePrev();
  }
  function slideNext() {
    swiper.current?.slideNext();
  }
  return (
    <Container>
      {openDialog && (
        <Modal isOpen={openDialog} setModalVisible={setOpenDialog}>
          <ModalContent>
            <ModalImageWrapper>
              <Image
                src={createProductImageSrc(images[imageIndex]?.url || '')}
                alt={images[imageIndex]?.description || ''}
                sizes="100vw"
                fill
              />
            </ModalImageWrapper>
            <ModalBottomSlider
              index={imageIndex + 1}
              total={images.length}
              onClick={handleModalImageSlide}
            />
          </ModalContent>
        </Modal>
      )}
      <ThumbNailWrapper>{images.map(thumbnailMapper)}</ThumbNailWrapper>
      <SwiperContainer className="swiper" ref={swiperElement}>
        <ProductFeaturesList $direction="column" $absolute={true}>
          {co2Label && showCo2Label && (
            <FeatureListItem>
              <CarbonLabel>{co2Label}</CarbonLabel>
            </FeatureListItem>
          )}
          {showAluminiumLabel && aluminiumLabel && (
            <FeatureListItem>
              <AluminiumLabel>{aluminiumLabel}</AluminiumLabel>
            </FeatureListItem>
          )}
        </ProductFeaturesList>
        <ZoomContainer onClick={showDialog}>
          <TextButton
            onClick={showDialog}
            aria-label={global?.showLargeImage}
            title={global?.showLargeImage ?? ''}
          >
            <Icon
              aria-hidden={true}
              focusable={false}
              width={24}
              height={24}
              svg="zoom"
            />
          </TextButton>
        </ZoomContainer>
        <SwiperWrapper className="swiper-wrapper">
          {images.map(slideMapper)}
        </SwiperWrapper>
        <NavigationButton
          ref={prevRef}
          aria-label={global.previous || ''}
          onClick={slidePrev}
          $flip
        >
          <ChevronDown
            aria-hidden={true}
            focusable={false}
            width={24}
            height={24}
          />
        </NavigationButton>
        <Pagination ref={paginationRef} />
        <NavigationButton
          ref={nextRef}
          aria-label={global.next || ''}
          onClick={slideNext}
        >
          <ChevronDown
            aria-hidden={true}
            focusable={false}
            width={24}
            height={24}
          />
        </NavigationButton>
      </SwiperContainer>
    </Container>
  );
};

interface ArrowProps {
  className?: string;
  onClick?: React.MouseEventHandler;
  'aria-label': string;
}

const Arrow: React.FC<ArrowProps> = ({ className, onClick, ...props }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      aria-label={props['aria-label']}
    />
  );
};
